import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  isWarehousePolygon,
  Warehouse,
  WarehouseActiveTime,
  WarehousePath,
  WarehousePolygon,
} from '@quickcommerceltd/zone'
import { GoogleMap, Marker, Polygon } from '@react-google-maps/api'
import { useFormik } from 'formik'
import keyBy from 'lodash/keyBy'
import { DateTime } from 'luxon'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { v4 } from 'uuid'
import { useAuth } from '../Auth/AuthProvider'
import { WarehouseActiveTimesView } from '../Warehouse/components/WarehouseActiveTimesView'
import { useWarehouses } from '../Warehouse/WarehousesProvider'
import { WarehousePolygonSaveDialog } from './components/WarehousePolygonSaveDialog'
import { getDistanceInMiles } from './helpers/getDistanceInMiles'
import { getResettedWarehousePathPoints } from './helpers/getResettedWarehousePathPoints'
import { getWarehousePath } from './helpers/getWarehousePath'
import { jsonToKml } from './helpers/jsonToKml'
import { useUpdateWarehousePolygon } from './hooks/useUpdateWarehousePolygon'
import { usePolygons } from './PolygonsProvider'
import UploadKml from './UploadKml'

interface Props {
  warehouse: Warehouse
  polygon: WarehousePolygon
}

const WarehousePolygonView: FC<Props> = ({ warehouse, polygon }) => {
  const { claims } = useAuth()
  const readOnly = !claims?.admin
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [polygons] = usePolygons()
  const [warehouses] = useWarehouses()
  const [isoWeekday, setIsoWeekday] = useState<number>(DateTime.local().weekday)
  const [updateWarehousePolygon, isUpdatingWarehousePolygon] = useUpdateWarehousePolygon()
  const [minute, setMinute] = useState(DateTime.local().hour * 60 + Math.floor(DateTime.local().minute / 5) * 5)
  const [isSavePolygonDialogOpen, setIsSavePolygonDialogOpen] = useState(false)

  const { values, errors, isSubmitting, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      polygon,
      isDraft: warehouse.isDraft,
    },
    onSubmit: () => saveWarehouseMap(),
  })

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.warn('errors', errors)
    }
  }, [errors])

  const warehousesSet = useMemo(() => keyBy(warehouses, 'id'), [warehouses])

  const [isAutoPolygonSelectEnabled, setIsAutoPolygonSelectEnabled] = useState(true)

  const [selectedPath, setSelectedPath] = useState<WarehousePath | undefined>(
    getWarehousePath(values.polygon, isoWeekday, minute)
  )

  const centerRef = useRef<google.maps.LatLngLiteral>({
    lat: warehouse.pickUpAddress.latitude,
    lng: warehouse.pickUpAddress.longitude,
  })

  const polygonRef = useRef<google.maps.Polygon | null>(null)
  const listenersRef = useRef<google.maps.MapsEventListener[]>([])
  const marker = { url: '/marker.svg' }
  const markerSmall = {
    url: '/marker.svg',
    scaledSize: new google.maps.Size(24, 34),
  }

  const toggleDialog = useCallback(
    (setFn: Dispatch<SetStateAction<boolean>>) => () => {
      setFn((prev) => !prev)
    },
    []
  )

  /**
   * On update time slider.
   */
  useEffect(() => {
    if (isAutoPolygonSelectEnabled) {
      const selectedPath = values.polygon.paths.find((path) => path.id === warehouse.activePathId)
      setSelectedPath(selectedPath)
    }
  }, [warehouse, values.polygon, minute, isoWeekday, isAutoPolygonSelectEnabled])

  /**
   * Update active times.
   */
  const updateActiveTimes = (nextActiveTimes: WarehouseActiveTime[]) => {
    if (!selectedPath) return

    setSelectedPath({
      ...selectedPath,
      activeTimes: nextActiveTimes,
    })

    setFieldValue('polygon', {
      ...values.polygon,
      paths: values.polygon.paths.map((path) => {
        return path.id === selectedPath.id ? { ...path, activeTimes: nextActiveTimes } : path
      }),
    })
  }

  /**
   * Add warehouse path.
   */
  const addWarehousePath = () => {
    const newWarehousePath: WarehousePath = {
      ...selectedPath,
      id: v4(),
      isAlwaysActive: selectedPath?.isAlwaysActive ?? false,
      name: selectedPath ? `${selectedPath.name} clone` : `New polygon`,
      points: selectedPath
        ? JSON.parse(JSON.stringify(selectedPath.points))
        : getResettedWarehousePathPoints(warehouse.pickUpAddress.latitude, warehouse.pickUpAddress.longitude),
    }

    setFieldValue('polygon', {
      ...values.polygon,
      paths: [...values.polygon.paths, newWarehousePath],
    })

    setSelectedPath(newWarehousePath)
  }

  /**
   * Remove warehouse path.
   */
  const removeWarehousePath = () => {
    const { paths } = values.polygon

    if (!selectedPath) return toast.error('There is no selected polygon.')
    if (paths.length === 1) return toast.error('There must be at least one polygon.')

    setFieldValue('polygon', {
      ...values.polygon,
      paths: paths.filter((path) => path.id !== selectedPath.id),
    })

    setSelectedPath(paths[0])
  }

  /**
   * On change polygon.
   */
  const onChangePolygon = useCallback(() => {
    if (!selectedPath) return toast.error('There is no selected polygon.')

    if (polygonRef.current) {
      const nextPathPoints = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })

      setSelectedPath((prev) => ({
        ...(prev ?? selectedPath),
        points: nextPathPoints,
      }))
    }
  }, [selectedPath])

  /**
   * On Goolge API loaded.
   */
  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon

      const path = polygon.getPath()

      // Delete path point by right-click
      google.maps.event.addListener(polygon, 'contextmenu', (e: any) => {
        if (!selectedPath) return

        let closestDistance = Infinity
        let closestIndex = -1

        polygon.getPath().forEach((point, index) => {
          const nextDistance = getDistanceInMiles(
            { latitude: point.lat(), longitude: point.lng() },
            { latitude: e.latLng.lat(), longitude: e.latLng.lng() }
          )

          if (nextDistance < closestDistance) {
            closestDistance = nextDistance
            closestIndex = index
          }
        })

        if (closestIndex >= 0) {
          polygon.getPath().removeAt(closestIndex)
          onChangePolygon()
        }
      })

      listenersRef.current.push(
        path.addListener('set_at', onChangePolygon),
        path.addListener('insert_at', onChangePolygon),
        path.addListener('remove_at', onChangePolygon)
      )
    },
    [selectedPath, onChangePolygon]
  )

  /**
   * On unmount component.
   */
  const onUnmount = () => {
    listenersRef.current.forEach((listener) => listener.remove())
    polygonRef.current = null
  }

  /**
   * Save.
   */
  const saveWarehouseMap = async () => {
    if (readOnly) {
      return alert('Only admin users can write warehouse document.')
    }

    try {
      const updatedPaths = values.polygon.paths.map((path) => {
        if (selectedPath?.id !== path.id) return path

        const updatedPath = { ...path, ...selectedPath }
        setSelectedPath(updatedPath)

        return updatedPath
      })

      const nextWarehousePolygon: WarehousePolygon = {
        id: warehouse.id,
        paths: updatedPaths,
      }

      if (!isWarehousePolygon(nextWarehousePolygon)) {
        return toast.error("Invalid data. Check if times start with zero e.g. '06:00' instead of '6:00'!")
      }

      await updateWarehousePolygon(nextWarehousePolygon)
      setIsSavePolygonDialogOpen(false)

      toast.success('Update was successful')
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const onKmlUpload = (kmlPoints: WarehousePath['points']) => {
    if (selectedPath) {
      setSelectedPath({
        ...selectedPath,
        points: kmlPoints,
      })
    }
  }

  const exportKmlFile = () => {
    if (!polygons?.length) return

    const kml = jsonToKml(polygons, isoWeekday, minute)

    // encode the data and auto download as a KML file
    const encodedUri = encodeURI(`data:text/xml;charset=utf-8,${kml}`)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'zapp-zones.kml')
    document.body.appendChild(link) // some browsers require this
    link.click()

    // clean up
    document.body.removeChild(link)
  }

  /**
   * On 24/7 cheeckbox change
   */
  const handleCheckboxChange = (id: string, checkValue: boolean) => {
    setFieldValue(
      'polygon.paths',
      values.polygon.paths.map((path) => (path.id === id ? { ...path, isAlwaysActive: checkValue } : path))
    )
  }

  const isDisabled = isSubmitting || readOnly || isUpdatingWarehousePolygon

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={7} lg={8} xl={9}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Weekday</Typography>
              <Slider
                key={isoWeekday}
                defaultValue={isoWeekday}
                onChangeCommitted={(_, value) => typeof value === 'number' && setIsoWeekday(value)}
                valueLabelFormat={(value) => DateTime.local().set({ weekday: value }).weekdayShort}
                valueLabelDisplay="auto"
                marks
                min={1}
                max={7}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography>Time</Typography>
              <Slider
                key={minute}
                defaultValue={minute}
                onChangeCommitted={(_, value) => typeof value === 'number' && setMinute(value)}
                valueLabelFormat={(value) =>
                  DateTime.local()
                    .set({
                      minute: value % 60,
                      hour: Math.floor(value / 60),
                    })
                    .toLocaleString(DateTime.TIME_24_SIMPLE)
                }
                valueLabelDisplay="auto"
                step={5}
                min={0}
                max={60 * 24 - 5}
              />
            </Grid>
          </Grid>
          <Box width="100%" height={600}>
            <GoogleMap mapContainerClassName={classes.mapContainer} center={centerRef.current} zoom={13}>
              {(polygons ?? [])
                .filter((item) => item.id !== warehouse.id)
                .map((item) => {
                  const path = getWarehousePath(item, isoWeekday, minute)

                  if (!path) return null

                  return (
                    <Polygon
                      key={item.id}
                      path={path.points}
                      options={{
                        fillColor: warehousesSet[item.id]?.isDraft
                          ? '#101010'
                          : warehousesSet[item.id]?.isTemporarilyClosed
                          ? '#FF0000'
                          : '#2cc976',
                      }}
                      onMouseDown={() => {
                        console.log('polygon', item)
                      }}
                    />
                  )
                })}
              <Polygon
                key={selectedPath?.id || 'none'}
                editable={!readOnly}
                options={{
                  fillColor: values.isDraft ? '#101010' : warehouse.isTemporarilyClosed ? '#FF0000' : '#2cc976',
                  fillOpacity: 0.5,
                  strokePosition: google.maps.StrokePosition.OUTSIDE,
                }}
                path={selectedPath?.points || []}
                onMouseUp={onChangePolygon}
                onDragEnd={onChangePolygon}
                onLoad={onLoad}
                onUnmount={onUnmount}
              />
              {warehouses?.map((warehouse) => (
                <Marker
                  icon={markerSmall}
                  key={warehouse.id}
                  title={warehouse.id}
                  opacity={0.9}
                  position={{
                    lat: warehouse.pickUpAddress.latitude,
                    lng: warehouse.pickUpAddress.longitude,
                  }}
                  onClick={() => {
                    navigate(`/warehouse/${warehouse.id}/polygon`)
                  }}
                />
              ))}

              <Marker
                icon={marker}
                position={{
                  lat: warehouse.pickUpAddress.latitude,
                  lng: warehouse.pickUpAddress.longitude,
                }}
              />
            </GoogleMap>
            <Button variant="contained" sx={{ marginTop: '1em' }} onClick={exportKmlFile}>
              Export KML
            </Button>
          </Box>
        </Grid>
        <Grid item sm={12} md={5} lg={4} xl={3}>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAutoPolygonSelectEnabled}
                  onChange={(event) => setIsAutoPolygonSelectEnabled(event.currentTarget.checked)}
                  color="primary"
                />
              }
              label="Auto select polygon"
            />
          </Box>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="polygon">Selected Polygon</InputLabel>
            <Select
              native
              renderValue={() => selectedPath?.name ?? 'None'}
              value={selectedPath?.id}
              onChange={(event) => {
                const nextSelectedPath = values.polygon.paths.find((path) => path.id === event.target.value)!

                setSelectedPath(nextSelectedPath)
              }}
              label="Selected Polygon"
              inputProps={{
                name: 'polygon',
                id: 'polygon',
              }}
              disabled={readOnly || isAutoPolygonSelectEnabled}
            >
              <option value={undefined}>None</option>
              {values.polygon.paths.map((path, index) => (
                <option key={index} value={path.id}>
                  {path.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {!isAutoPolygonSelectEnabled && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Button disabled={readOnly} color="primary" size="small" onClick={addWarehousePath}>
                  {selectedPath ? 'Clone' : 'Add'}
                </Button>
                {selectedPath && (
                  <Button color="secondary" size="small" disabled={readOnly} onClick={removeWarehousePath}>
                    Delete
                  </Button>
                )}
              </Box>
              {selectedPath && (
                <>
                  <Box mt={2}>
                    <TextField
                      onChange={(event) => {
                        setSelectedPath({
                          ...selectedPath,
                          name: event.currentTarget.value,
                        })
                      }}
                      value={selectedPath.name}
                      label="Name"
                      variant="outlined"
                      fullWidth
                      disabled={readOnly}
                    />
                  </Box>
                  <Box py={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="polygon" shrink={true}>
                        Contingency Polygon for
                      </InputLabel>
                      <Select
                        native
                        label="Contingency Polygon for"
                        value={selectedPath?.contingencyPolygonFor ?? ''}
                        renderValue={() => selectedPath?.contingencyPolygonFor || 'None'}
                        onChange={(event) => {
                          const value = event.target.value as string
                          setSelectedPath({
                            ...selectedPath,
                            contingencyPolygonFor: value,
                          })
                        }}
                        disabled={readOnly}
                      >
                        <option value="">None</option>
                        {warehouses?.map((warehouse, index) => (
                          <option key={index} value={warehouse.id}>
                            {warehouse.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box py={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={!!selectedPath.isDraft}
                          color="primary"
                          onChange={(event) => {
                            setSelectedPath({
                              ...selectedPath,
                              isDraft: event.currentTarget.checked,
                            })
                          }}
                        />
                      }
                      label="Draft Polygon"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={selectedPath.isAlwaysActive}
                          color="primary"
                          onChange={(event) => {
                            setSelectedPath({
                              ...selectedPath,
                              isAlwaysActive: event.currentTarget.checked,
                            })
                            handleCheckboxChange(selectedPath.id, event.currentTarget.checked)
                          }}
                        />
                      }
                      label="24/7"
                    />
                  </Box>
                  {!selectedPath.isAlwaysActive && (
                    <WarehouseActiveTimesView
                      activeTimes={selectedPath.activeTimes}
                      updateActiveTimes={updateActiveTimes}
                      disabled={readOnly}
                    />
                  )}
                </>
              )}
            </>
          )}
          <Box mb={2} mt={2} display="flex" flexDirection="row">
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabled}
                onClick={toggleDialog(setIsSavePolygonDialogOpen)}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Stack direction="row">
            <UploadKml onUpload={onKmlUpload} isDisabled={isAutoPolygonSelectEnabled || isDisabled} />
          </Stack>
        </Grid>
      </Grid>
      {isSavePolygonDialogOpen && (
        <WarehousePolygonSaveDialog
          warehouse={warehouse}
          onClose={toggleDialog(setIsSavePolygonDialogOpen)}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          formPolygon={values.polygon}
        />
      )}
    </form>
  )
}

export default WarehousePolygonView

const useStyles = makeStyles()(() => ({
  mapContainer: {
    width: '100%',
    height: '100%',
  },
}))
