import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  StorefrontData,
  StorefrontProvider,
  StorefrontStatus,
  Warehouse,
  WarehouseStatus,
} from '@quickcommerceltd/zone'
import { useFormik } from 'formik'
import { FC, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import * as yup from 'yup'
import { useAuth } from '../Auth/AuthProvider'
import { getWarehouseStatus } from '../Warehouse/helpers/getWarehouseStatus'
import { updateStorefrontStatusCallable } from './callables/updateStorefrontStatusCallable'
import { OpeningHoursForm } from './components/OpeningHoursForm'
import { StorefrontItem } from './components/StorefrontItem'
import { WarehouseAddressForm } from './components/WarehouseAddressForm'
import WarehouseStatusModal from './components/WarehouseStatusModal'
import { WarehouseStatusView } from './components/WarehouseStatusView'
import { useUpdateWarehouseStatus } from './hooks/useUpdateWarehouseStatus'
interface Props {
  warehouse: Warehouse
}

type FormValues = {
  warehouseStatus: WarehouseStatus
  reason: string
}

const validationSchema = yup.object().shape({
  warehouseStatus: yup.string().required(),
  reason: yup.string().required(),
})

export const WarehouseSettingsView: FC<Props> = (props) => {
  const auth = useAuth()
  const warehouseStatus = getWarehouseStatus(props.warehouse)
  const [updateWarehouseStatus, isUpdatingWarehouseStatus] = useUpdateWarehouseStatus()
  const [isCloseStoreModalVisible, setIsCloseStoreModalVisible] = useState(false)
  const isMobile = useMediaQuery('(min-width:900px)')

  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik<FormValues>({
    validationSchema,
    initialValues: {
      warehouseStatus,
      reason: props.warehouse.manuallyClosedReason || 'Opened store',
    },
    onSubmit: async (values: FormValues) => {
      await updateWarehouseStatus({
        warehouseId: props.warehouse.id,
        status: values.warehouseStatus,
        reason: values.reason,
      })

      setIsCloseStoreModalVisible(false)
    },
  })

  const storefronts = useMemo(() => {
    if (!props.warehouse.storefronts) return []
    return Object.entries(props.warehouse.storefronts) as [StorefrontProvider, StorefrontData][]
  }, [props.warehouse])

  const handleStorefrontStatusChange = (provider: StorefrontProvider, status: StorefrontStatus) => {
    return updateStorefrontStatusCallable({
      warehouseId: props.warehouse.id,
      provider,
      status,
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  const isAdmin = Boolean(auth.claims?.admin)
  const isManager = Boolean(auth.claims?.manager)
  const isAdminOrManager = isAdmin || isManager
  const isInContingencyMode =
    !!props.warehouse.isInContingencyMode && // warehouse area is covered by other warehouse polygons
    !props.warehouse.inContingencyModeFor
  const readOnly = !isAdminOrManager || isInContingencyMode

  return (
    <Grid container>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Typography variant="h6" mb={1}>
              Status
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="warehouseStatus"
                  value={values.warehouseStatus}
                  error={!!errors.warehouseStatus}
                  disabled={readOnly}
                  onChange={(e, value) => {
                    handleChange(e)
                    if (warehouseStatus !== value) {
                      setIsCloseStoreModalVisible(true)
                    }
                  }}
                >
                  <MenuItem value="DRAFT" disabled={!isAdmin}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <WarehouseStatusView warehouseStatus="DRAFT" />
                      <Box ml={1}>Draft</Box>
                    </Box>
                  </MenuItem>
                  <MenuItem value="CLOSED" disabled={!isAdminOrManager}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <WarehouseStatusView warehouseStatus="CLOSED" />
                      <Box ml={1}>Closed</Box>
                    </Box>
                  </MenuItem>
                  <MenuItem value="OPEN" disabled={!isAdminOrManager}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <WarehouseStatusView warehouseStatus="OPEN" />
                      <Box ml={1}>Open</Box>
                    </Box>
                  </MenuItem>
                </Select>
                <WarehouseStatusModal
                  isLoading={isUpdatingWarehouseStatus}
                  isOpen={isCloseStoreModalVisible}
                  nextWarehouseStatus={values.warehouseStatus}
                  onSubmit={(reason: string) => {
                    setFieldValue('reason', reason)
                    handleSubmit()
                  }}
                  onClose={() => {
                    setIsCloseStoreModalVisible(false)
                    setFieldValue('warehouseStatus', warehouseStatus)
                  }}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid item sm={12} md={6}>
            <Stack alignItems={isMobile ? 'center' : 'flex-start'}>
              <OpeningHoursForm warehouse={props.warehouse} disabled={!isAdminOrManager} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} hidden={!storefronts.length}>
        <Box my={2}>
          <Box mb={1}>
            <Typography variant="h6">Storefronts</Typography>
          </Box>

          <Grid container spacing={2}>
            {storefronts.map(([provider, data]) => (
              <Grid item sm={12} md={6} key={provider}>
                <StorefrontItem
                  provider={provider}
                  data={data}
                  onStorefrontStatusChanged={handleStorefrontStatusChange}
                  disabled={readOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={12} md={12}>
        <Box my={2}>
          <Box mb={1}>
            <Typography variant="h6">Address</Typography>
          </Box>

          <WarehouseAddressForm warehouse={props.warehouse} disabled={!isAdminOrManager} />
        </Box>
      </Grid>
    </Grid>
  )
}
