import { Alert } from '@mui/material'
import { UpdateStoreWarningRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { updateStoreWarningCallable } from '../callables/updateStoreWarningCallable'

export function useUpdateStoreWarning(): [
  (updateStoreWarningRequest: UpdateStoreWarningRequest) => Promise<void>,
  boolean
] {
  const [isUpdatingStoreWarning, setIsUpdatingStoreWarning] = useState(false)

  const updateStoreWarning = useCallback(async (updateStoreWarningRequest: UpdateStoreWarningRequest) => {
    try {
      setIsUpdatingStoreWarning(true)

      await updateStoreWarningCallable(updateStoreWarningRequest)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsUpdatingStoreWarning(false)
    }
  }, [])

  return [updateStoreWarning, isUpdatingStoreWarning]
}
