import { Alert } from '@mui/material'
import { DeleteStoreWarningRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { deleteStoreWarningCallable } from '../callables/deleteStoreWarningCallable'

export function useDeleteStoreWarning(): [
  (deleteStoreWarningRequest: DeleteStoreWarningRequest) => Promise<void>,
  boolean
] {
  const [isDeletingStoreWarning, setIsDeletingStoreWarning] = useState(false)

  const deleteStoreWarning = useCallback(async (deleteStoreWarningRequest: DeleteStoreWarningRequest) => {
    try {
      setIsDeletingStoreWarning(true)

      await deleteStoreWarningCallable(deleteStoreWarningRequest)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsDeletingStoreWarning(false)
    }
  }, [])

  return [deleteStoreWarning, isDeletingStoreWarning]
}
