import { Box } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { useMemo } from 'react'
import { usePolygons } from '../../Polygon/PolygonsProvider'

const WarehouseStatus = ({ warehouse }: { warehouse: Warehouse }) => {
  const [polygons] = usePolygons()
  const activePathId = warehouse.activePathId
  const activePathName = useMemo(() => {
    const polygon = polygons?.find((polygon) => polygon.id === warehouse.id)
    return polygon?.paths.find((path) => path.id === activePathId)?.name
  }, [polygons, warehouse, activePathId])

  return (
    <Box>
      <Box padding={1} margin={-1} mr={0} fontSize="0.9em">
        Order Stack Size: <b>{warehouse.currentOrderStackSize || 0}</b>
        {' · '}
        Polygon: <b>{activePathName || <i>inactive</i>}</b>
      </Box>
    </Box>
  )
}

export default WarehouseStatus
