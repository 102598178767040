import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme, createTheme } from '@mui/material/styles'
import '@mui/styles'
import { FC } from 'react'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#02BAFF',
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          zIndex: 1200,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          lineHeight: '1.2em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: { textTransform: 'none', fontWeight: 600 },
        textSizeSmall: { textTransform: 'uppercase' },
      },
    },
  },
})

export const ThemeProvider: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <>
        <CssBaseline />
        {children}
      </>
    </MuiThemeProvider>
  </StyledEngineProvider>
)
