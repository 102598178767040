import { Alert } from '@mui/material'
import { UpdateThrottlingConfigRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { updateThrottlingConfigCallable } from '../callables/updateThrottlingConfigCallable'

export function useUpdateThrottlingConfig(): [
  (updateThrottlingConfigRequest: UpdateThrottlingConfigRequest) => Promise<void>,
  boolean
] {
  const [isUpdatingThrottlingConfig, setIsUpdatingThrottlingConfig] = useState(false)

  const updateThrottlingConfig = useCallback(async (updateThrottlingConfigRequest: UpdateThrottlingConfigRequest) => {
    try {
      setIsUpdatingThrottlingConfig(true)

      await updateThrottlingConfigCallable(updateThrottlingConfigRequest)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsUpdatingThrottlingConfig(false)
    }
  }, [])

  return [updateThrottlingConfig, isUpdatingThrottlingConfig]
}
