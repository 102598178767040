import { Alert } from '@mui/material'
import { UpdateWarehousePolygonRequest } from '@quickcommerceltd/zone'
import { useCallback, useState } from 'react'
import { updateWarehousePolygonCallable } from '../callables/updateWarehousePolygonCallable'

export function useUpdateWarehousePolygon(): [
  (nextWarehousePolygon: UpdateWarehousePolygonRequest) => Promise<void>,
  boolean
] {
  const [isUpdatingWarehousePolygon, setIsUpdatingWarehousePolygon] = useState(false)

  const updateWarehousePolygon = useCallback(async (nextWarehousePolygon: UpdateWarehousePolygonRequest) => {
    console.log(nextWarehousePolygon, 'nextWarehousePolygon')
    try {
      setIsUpdatingWarehousePolygon(true)

      await updateWarehousePolygonCallable(nextWarehousePolygon)
    } catch (error: any) {
      Alert(error.message)
    } finally {
      setIsUpdatingWarehousePolygon(false)
    }
  }, [])

  return [updateWarehousePolygon, isUpdatingWarehousePolygon]
}
