import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { makeStyles } from 'tss-react/mui'
import { db } from '../../firebase'
import { getUserClaimCollectionRef } from '../../firebase/helpers/getCollectionRef'
import AppTopBar from '../App/AppTopBar'
import { useAuth } from './AuthProvider'

export interface UserClaim {
  id: string // email address as doc.id
  note: string
  admin: boolean | undefined
  manager: boolean | undefined
  user: boolean | undefined
}

enum UserRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  USER = 'user',
}

const useStyles = makeStyles()(() => ({
  textfield: {
    flexGrow: 1,
    minWidth: 200,
    marginRight: 8,
  },
}))

const Authorize: FC = () => {
  const { claims } = useAuth()
  const { classes } = useStyles()
  const [editUser, setEditUser] = useState({} as UserClaim)

  const [userList, loading, error] = useCollectionData<UserClaim>(getUserClaimCollectionRef())

  const handleChange = (event: any) => {
    const { name, value } = event.target ?? {}

    if (name === 'role') {
      setEditUser({
        ...editUser,
        admin: false,
        manager: false,
        user: false,
        [value]: true,
      })
      return
    }

    setEditUser({ ...editUser, [name]: value })
  }

  const deleteUser = (user: UserClaim): void => {
    if (!user?.id) return
    db.collection('user_claims').doc(user.id).delete()
  }

  const saveUser = (user: UserClaim) => {
    if (!user?.id) return
    db.collection('user_claims').doc(user.id).set(user)
  }

  if (error) {
    return (
      <>
        <AppBar title="Authorize" />
        <Box padding={4} style={{ textAlign: 'center' }}>
          {error.name}: {error.message}
        </Box>
      </>
    )
  }

  if (loading) {
    return (
      <>
        <AppBar title="Authorize" />
        <Box key="loading" display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    )
  }

  return (
    <>
      <AppBar title="Authorize" />

      <AppTopBar>
        <Box flex={1}>
          <Typography variant="h6">Authorize</Typography>
        </Box>
      </AppTopBar>

      {claims?.admin && (
        <Box m={4}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              saveUser(editUser)
            }}
          >
            <Typography variant="h6" style={{ marginBottom: '8px' }}>
              Create/Edit
            </Typography>
            <Box display="flex">
              <TextField
                name="id"
                variant="outlined"
                label="Email"
                value={editUser.id ?? ''}
                onChange={handleChange}
                classes={{ root: classes.textfield }}
              />
              <TextField
                name="note"
                variant="outlined"
                label="Note"
                value={editUser.note ?? ''}
                onChange={handleChange}
                classes={{ root: classes.textfield }}
              />
              <TextField
                select
                name="role"
                variant="outlined"
                label="user roles"
                SelectProps={{
                  multiple: false,
                  value: [
                    editUser.admin && UserRole.ADMIN,
                    editUser.manager && UserRole.MANAGER,
                    editUser.user && UserRole.USER,
                  ].filter(Boolean),
                  onChange: handleChange,
                }}
                classes={{ root: classes.textfield }}
              >
                {[UserRole.ADMIN, UserRole.MANAGER, UserRole.USER].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button color="primary" variant="outlined" type="button" onClick={() => setEditUser({} as UserClaim)}>
                cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {claims?.admin ? (
        <Box ml={4} mr={4}>
          <Typography variant="h6">Users</Typography>
          <TableContainer>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList?.map((user: UserClaim, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {user.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {user.note}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {[user.admin && UserRole.ADMIN, user.manager && UserRole.MANAGER, user.user && UserRole.USER]
                        .filter(Boolean)
                        .join(', ')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button onClick={() => setEditUser(user)} size="small" color="primary" variant="outlined">
                        edit
                      </Button>
                      <Button onClick={() => deleteUser(user)} size="small" color="primary">
                        del
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <div>You have no permissions to do anything here.</div>
      )}
    </>
  )
}

export default Authorize
