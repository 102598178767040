import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Chip, styled, Tooltip } from '@mui/material'
import { Warehouse } from '@quickcommerceltd/zone'
import { FC } from 'react'
import { useWarehouseOpeningHoursCoverage } from '../hooks/useWarehouseOpeningHoursCoverage'
import { WarehouseCoverageTable } from './WarehouseCoverageTable'

interface Props {
  warehouse: Warehouse
}

export const WarehouseCoverageChip: FC<Props> = (props) => {
  const coverage = useWarehouseOpeningHoursCoverage(props.warehouse)

  return (
    <Tooltip
      title={
        !coverage.isFullyCovered ? (
          <WarehouseCoverageTable
            isFullyCovered={coverage.isFullyCovered}
            notCoveredWeekdays={coverage.notCoveredWeekdays}
            fontSize={'0.75rem'}
          />
        ) : (
          ''
        )
      }
      componentsProps={{
        popper: {
          sx: {
            '& .MuiTooltip-tooltip': {
              border: '2px solid black',
              padding: 0,
              borderRadius: '8px',
              backgroundColor: 'white',
            },
          },
        },
      }}
    >
      <StyledChip
        icon={coverage.isFullyCovered ? <CheckCircleOutlinedIcon /> : <ReportProblemOutlinedIcon />}
        isFullyCovered={coverage.isFullyCovered}
        label={coverage.isFullyCovered ? 'Fully Covered' : 'Not Fully Covered'}
        size="small"
        color="secondary"
      ></StyledChip>
    </Tooltip>
  )
}

const StyledChip = styled(Chip)<{ isFullyCovered: boolean }>(({ isFullyCovered }) => {
  return {
    backgroundColor: isFullyCovered ? '#00805C' : '#990F0F',
    marginLeft: '4px',
    marginBottom: '2px',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '10px',
    height: '18px',
    padding: '2px',
    '& .MuiChip-icon': { marginLeft: '0px', height: '12px' },
  }
})
