import { Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import AppTopBar from '../App/AppTopBar'
import { useAuth } from '../Auth/AuthProvider'
import { createWarehouseCallable } from './callables/createWarehouseCallable'

type FormData = {
  id: string
  address1: string
  city: string
  name: string
  shortName: string
  countryCode: string
  latitude: number
  longitude: number
  zip: string
  phone: string
  saleorWarehouseId: string
  isDistributionCenter: boolean
}

const validationSchema = yup.object().shape({
  id: yup
    .string()
    .required()
    .matches(/^[a-z]{2}_[a-z]+_[a-z-]+?$/),
  name: yup.string().required(),
  shortName: yup.string().required(),
  city: yup.string().required(),
  address1: yup.string().required(),
  latitude: yup.number().required(),
  longitude: yup.number().required(),
  zip: yup.string().required(),
  phone: yup.string().required(),
  countryCode: yup.string().required(),
  saleorWarehouseId: yup.string().required(),
  isDistributionCenter: yup.boolean().required(),
})

const INITIAL_VALUES: FormData = {
  id: '',
  name: '',
  shortName: '',
  city: '',
  address1: '',
  latitude: 0,
  longitude: 0,
  zip: '',
  phone: '',
  countryCode: '',
  saleorWarehouseId: '',
  isDistributionCenter: false,
}

const WarehouseCreateView: FC = () => {
  const navigate = useNavigate()
  const { user, claims } = useAuth()

  const { values, errors, handleChange, handleSubmit, isSubmitting } = useFormik<FormData>({
    validationSchema,
    initialValues: INITIAL_VALUES,
    onSubmit: async (values: FormData) => {
      try {
        await createWarehouseCallable({
          id: values.id,
          name: values.name,
          shortName: values.shortName,
          saleorWarehouseId: values.saleorWarehouseId,
          isDistributionCenter: values.isDistributionCenter,
          pickUpAddress: {
            address1: values.address1,
            city: values.city,
            countryCode: values.countryCode.toUpperCase(),
            latitude: values.latitude,
            longitude: values.longitude,
            phone: values.phone,
            zip: values.zip.toUpperCase(),
          },
        })

        navigate(`/warehouse/${values.id}/polygon`)
      } catch (error: any) {
        alert(error.message)
      }
    },
  })

  const isAdmin = claims?.admin
  const isLoading = isSubmitting

  if (!isAdmin) {
    return (
      <>
        <AppTopBar>
          <Box flex={1}>
            <Typography variant="h6" noWrap>
              Welcome {user?.displayName},
            </Typography>
          </Box>
        </AppTopBar>
        <Box p={2}>
          <Typography>Please select a store from the navigation bar on the left.</Typography>
        </Box>
      </>
    )
  }

  return (
    <Box p={2}>
      <AppTopBar>
        <Box flex={1}>
          <Typography variant="h6" noWrap>
            <strong>Create new store</strong>
          </Typography>
        </Box>
      </AppTopBar>
      <form onSubmit={handleSubmit}>
        <Box mb={1}>
          <Typography variant="h6">General</Typography>
          <TextField
            name="id"
            onChange={handleChange}
            error={!!errors.id}
            value={values.id}
            placeholder="e.g. uk_london_name-of-store"
            label="ID (Use underscore between country code, city and store. Maximum two underscore. Dashes in the store name.)"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="name"
            onChange={handleChange}
            error={!!errors.name}
            value={values.name}
            placeholder="e.g. Old Brompton Road"
            label="Full Name"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="shortName"
            onChange={handleChange}
            error={!!errors.shortName}
            value={values.shortName}
            placeholder="e.g. KEN (3 unique capital letters)"
            label="Short Name"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mt={3} mb={1}>
          <Typography variant="h6">Address</Typography>
          <TextField
            name="address1"
            onChange={handleChange}
            error={!!errors.address1}
            value={values.address1}
            placeholder="e.g. 154 Old Brompton Road"
            label="Address"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="city"
            onChange={handleChange}
            error={!!errors.city}
            value={values.city}
            placeholder="e.g. London"
            label="City"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="zip"
            onChange={handleChange}
            error={!!errors.zip}
            value={values.zip}
            placeholder="e.g. SW7 3LE"
            label="Zip"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="countryCode"
            onChange={handleChange}
            error={!!errors.countryCode}
            value={values.countryCode}
            placeholder="e.g. GB (not uk)"
            label="Country Code (ISO 3166-1 alpha-2)"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1}>
          <TextField
            name="phone"
            onChange={handleChange}
            error={!!errors.phone}
            value={values.phone}
            placeholder="e.g. +44 123 456 7890"
            label="Phone Number"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={1} display="flex" flexDirection="row">
          <Box flex={1}>
            <TextField
              name="latitude"
              onChange={handleChange}
              error={!!errors.latitude}
              value={values.latitude}
              placeholder="e.g. 51.49233"
              label="Latitude"
              type="number"
              variant="filled"
              fullWidth
            />
          </Box>
          <Box flex={1} ml={1}>
            <TextField
              name="longitude"
              onChange={handleChange}
              error={!!errors.longitude}
              value={values.longitude}
              placeholder="e.g. -0.17781"
              label="Longitude"
              type="number"
              variant="filled"
              fullWidth
            />
          </Box>
        </Box>
        <Box mt={3} mb={1}>
          <Typography variant="h6">Other</Typography>
          <TextField
            name="saleorWarehouseId"
            onChange={handleChange}
            error={!!errors.saleorWarehouseId}
            value={values.saleorWarehouseId}
            placeholder="e.g. V2FyZWhvdXNlOmJlNzFiZjkyLWU5YTgtADMwNS1iMmMxLBQyMPQ5NWQ3Zjk9Yw=="
            label="ZCommerce Warehouse ID"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={<Checkbox onChange={handleChange} name="isDistributionCenter" color="primary" />}
            label="Distribution Center"
          />
        </Box>
        <Button variant="contained" color="primary" type="submit" disabled={isLoading} style={{ minWidth: '150px' }}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : <span>Create Store</span>}
        </Button>
      </form>
    </Box>
  )
}

export default WarehouseCreateView
